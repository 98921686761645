.blueFolderContainer {
    padding: 1%;
    color: var(--primary-color) !important;
    border-radius: 0.5em;
    display: flex;
    align-items: center;
}

.blueFileContainer {
    /* color: var(--primary-color) !important; */
    border-radius: 0.5em;
    display: flex;
    align-items: center;
    font-weight: lighter !important;
}

.blueIconContainer {
    color: var(--primary-color);
    border-radius: 0.3em;
}

.blueMenuContainer {
    color: var(--primary-color);
    border-radius: 0.3em;
}

.folder-name {
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
}

.file-name {
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    /* font-style: italic; */
        color: gray;
}

.file-list-container {
    max-height: 45%;
    overflow-y: scroll;
}

.no-vertical-scroll {
    overflow-y: hidden !important;
    overflow: hidden !important;
}

.limit-container-height {
    max-height: 97vh !important;
}

.smaller-font {
    font-size: 50% !important;
}

.company-wrapper th:nth-child(1),
.company-wrapper td:nth-child(1),
.company-wrapper th:nth-child(3),
.company-wrapper td:nth-child(3) {
    text-align: left;
    padding: 0.5%;
    padding-left: 0 !important;
}

.company-wrapper th:nth-child(2),
.company-wrapper td:nth-child(2) {
    text-align: left;
    padding: 0.5%;
    padding-left: 0 !important;
    width: 35%;
}

.company-wrapper th:nth-child(4),
.company-wrapper td:nth-child(4) {
    padding: 0.5%;
    display: flex;
    justify-content: end;
    padding-left: 0 !important;
}
.bullet-list {
    list-style-type: disc !important
}