.SSS-wrapper tbody {
    max-height: 52vh;
}

.SSS-wrapper th:nth-child(1),
.SSS-wrapper td:nth-child(1) {
    padding: 0.5%;
    padding-left: 0 !important;
    text-align: left;
}

.SSS-wrapper th:nth-child(2),
.SSS-wrapper td:nth-child(2),
.SSS-wrapper th:nth-child(3),
.SSS-wrapper td:nth-child(3),
.SSS-wrapper th:nth-child(4),
.SSS-wrapper td:nth-child(4),
.SSS-wrapper th:nth-child(5),
.SSS-wrapper td:nth-child(5) {
    padding: 0.5%;
    padding-right: 0 !important;
    text-align: right !important;
}

.coop-table-wrapper th:nth-child(1),
.coop-table-wrapper td:nth-child(1) {
    padding: 0.5%;
    text-align: left !important;
    padding-left: 0 !important;
    width: 20%;
}

.coop-table-wrapper th:nth-child(2),
.coop-table-wrapper td:nth-child(2) {
    padding: 0.5%;
    text-align: right !important;
    padding-left: 0 !important;
}

.coop-table-wrapper th:nth-child(3),
.coop-table-wrapper td:nth-child(3) {
    padding: 0.5%;
    display: flex;
    justify-content: end;
    padding-left: 0 !important;
}
a {
    text-decoration: none;
    color: inherit;
}

/* .genSSS-container {
    padding: 1%;
} */

.yes-border {
    color: var(--primary-color);
    font-size: 1vw;
    padding-right: 25% !important;
    border-color: var(--primary-color) !important;
}
.no-border {
    border-color: white !important;
}
.SSS-form-control {
    border: 1px solid var(--primary-color) !important;
    border-radius: 4px !important;
    width: 10vw !important
}

.filter-btn-wrapper {
    position: relative;
    height: 100%;
}

.filter-btn-wrapper button {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    width: 80% !important;
}

.SSS-table thead {
    border-top: 2px solid var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
    text-transform: uppercase;
    /* overflow-x: scroll !important; */
}

.SSS-table thead th {
    width: 10vw !important;
}
.SSS-table thead tr,
.SSS-table tbody tr {
    display: block;
    vertical-align: middle;
}

.SSS-table {
    border-bottom: 1px solid var(--fourth-color);
    overflow-x: scroll !important;
    table-layout: auto;
}

.nochange:hover {
    color: inherit !important;
}

.blue {
    background-color: #054c87 !important;
}

@media screen {
    .printOnly {
        display: none;
    }
}
@media print {
    @page {
        width: 100vw !important;
        size: auto;
        margin: 16mm 16mm 16mm 16mm;
        /* margin-left: -10vw !important; */
        }

    .noprint {
        display: none;
    }
}

.print-button {
    width: 10vw;
    background-color: #0667b5;
    border: none;
    color: white;
    border-radius: 250em;
}
.print-btn-act {
    background-color: var(--primary-color);
    border: none;
    color: white;
    border-radius: 250em;
    width: 5vw !important;
}

.no-margin-top {
    margin-top: -2vw !important;
}
.no-margin-bottom {
    margin-bottom: -1vw !important;
}
.view-SSS {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 90%;
    font-weight: bold;
    stroke: none;
    stroke-width: 0;
    margin-left: 10%;
}
.content-SSS {
    text-align: right;
}
.title-SSS {
    text-align: left;
    padding-left: 0.5%;
}
.leftCol {
    flex: 0 0 30%;
    background-color: none;
    display: right;
    align-items: left;
    padding: 0%;
    padding-left: 1%;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}
.midCol {
    flex: 0 0 5%;
    background-color: none;
    display: right;
    align-items: left;
    padding: 0%;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}
.rightCol {
    flex: 0 0 25%;
    background-color: none;
    display: flex;
    padding: 0%;
    align-items: top;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}