.memo-wrapper tbody {
    max-height: 52vh;
}

.memo-wrapper th:nth-child(1),
.memo-wrapper th:nth-child(2),
.memo-wrapper th:nth-child(3),
.memo-wrapper th:nth-child(5),
.memo-wrapper td:nth-child(1),
.memo-wrapper td:nth-child(2),
.memo-wrapper td:nth-child(3),
.memo-wrapper td:nth-child(5) {
    padding: 0.5%;
    text-align: left !important;
    padding-left: 0 !important;
}

.memo-wrapper th:nth-child(4),
.memo-wrapper td:nth-child(4) {
    padding: 0.5%;
    text-align: left !important;
    padding-left: 0 !important;
    width: 30%;
    }
    
    .memo-wrapper th:nth-child(6),
    .memo-wrapper td:nth-child(6) {
        padding: 0.5%;
        display: flex;
        justify-content: end;
        padding-left: 0 !important;
}
.header p {
    margin-bottom: 0 !important;
}

/* contracts */
.contracts .nav-link {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    color: black;
    text-transform: uppercase;
}

.contracts .nav-link.active, .contracts .show>.nav-link {
    border-bottom: 2px solid !important;
    border-top: none;
    border-left: none;
    border-right: none;
    color: black !important;
    background-color: transparent !important;
    border-color: none none black none;
}

.contracts .nav-link:focus, .contracts .nav-link:hover {
    color: black;
}
.header-memo{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    margin-left: 2%;
    margin-top: 2%;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #000000;
}

.memo-icon{
    font-size: 40px !important;
    position: relative;
    width: 40px;
    height: 40px;
    left: 10px;
    top: 0;
}

/* Table */
.comm-header-memo {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9vw;
    line-height: 46px;
    letter-spacing: 0.02em;
    /* color: #000000; */
        color: var(--fourth-color);
        /* margin-left: 1em; */
    margin-top: .0em;
}

.dropdown-btn-memo {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    width: 7vw;
    padding: 1%;
    display: flex;
    justify-content: end !important;
        /* margin-left: 60% !important; */
}

.export-button-memo {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 8%;
    border-radius: 500em;
    width: 150% !important;
    margin-left: 35%;
    /* border-radius: 999em 999em 999em 999em */
}

.message-header {
    color: var(--primary-color);
    font-size: 150%;
    font-weight: bold;
}

.code-container {
    border-radius: 0.5em;
    background-color: var(--primary-color);
    color: white;
    /* text-align: center; */
}

.acc-container {
    border-radius: 0.5em;
    background-color: var(--secondary-color);
    color: black;
    /* text-align: center; */
}

.date-container {
    border-radius: 0.5em;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    /* text-align: center; */
}

.black-text {
    color: black;
}
.quill-container {
    border-color: var(--primary-color) !important;
}
/* INTERNS INPUT <3 */

.logo-cont {
    display: flex;
    align-items: center;
    justify-content: center;
}

.topCol {
    flex: 0 0 10%;
    background-color: none;
    display: right;
    align-items: left;
    padding: 0;
    justify-content: left;
    list-style-type: none;
    margin-right: -1rem;
    margin-left: -1rem;
    margin-top: -.5rem;
}

.toprightCol {
    flex: 0 0 70%;
    background-color: none;
    display: flex;
    padding: 0%;
    align-items: top;
    justify-content: left;
    list-style-type: none;
    line-height: 14px;
}

.leftCol {
    flex: 0 0 25%;
    background-color: none;
    display: right;
    align-items: left;
    padding: 0%;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}

.midCol {
    flex: 0 0 5%;
    background-color: none;
    display: right;
    align-items: left;
    padding: 0%;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}

.rightCol {
    flex: 0 0 70%;
    background-color: none;
    display: flex;
    padding: 0%;
    align-items: top;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}

.print {
    padding-top: 0%;
}

.payslip-logo {
    width: 6em;
}


.headeraddress {
    font-size: 1vw;
    padding: 0%;
}

.assigned {
    padding-bottom: 5%;
}

.memoheader {
    padding-left: 0;
    text-align: center;
    padding-bottom: 5%;
}

.role {
    padding-bottom: 10%;
    padding-left: 9%;
}

.headerline {
    width: 100%;
    height: 2px;
    border: 0 none;
    margin-right: auto;
    margin-left: auto;
    margin-top: 7px;
    margin-bottom: 30px;
    background-color: black;
}

.updatedon {
    padding-left: 0;
    text-align: center;
    padding-bottom: 5%;
    margin-top: 10px;
    overflow-wrap: break-word;
}

@media (min-width: 280px) and (max-width: 359px) {
    .comm-header-memo {
        font-size: 8vw;
    }
    .export-button-memo {
        margin-left: -45% !important;
        width: 235% !important;
    }
    .search-memo{
        margin-top: -4%;
        margin-left: 2%;
    }
    .dropdown-btn-memo {
        margin-left: 0 !important;
        width: 15vw;
    }
}

@media (min-width: 360px) and (max-width: 374px) {
    .comm-header-memo {
        font-size: 2.2vw;
    }
    .export-button-memo {
        padding: 0 !important;
        width: 150% !important;
        margin-left: 70%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-memo{
        margin-top: -8%;
    }
    .dropdown-btn-memo {
        margin-left: 0 !important;
        width: 15vw;
    }
}

@media (min-width: 375px) and (max-width: 389px) {
    .comm-header-memo {
        font-size: 3vw;
    }
    .export-button-memo {
        padding: 0 !important;
        width: 150% !important;
        margin-left: 63%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-memo{
        margin-top: -8%;
    }
    .dropdown-btn-memo {
        margin-left: 0 !important;
        width: 15vw;
    }

}

/* IPhone 12 Pro */
@media (min-width: 390px) and (max-width: 392px) and (-webkit-device-pixel-ratio : 3)and (orientation : portrait){
    .comm-header-memo {
        font-size: 3.5vw;
    }
    .export-button-memo {
        padding: 0 !important;
        width: 150% !important;
        margin-left: 57%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-memo{
        margin-top: -7%;
    }
    .dropdown-btn-memo {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 393px) and (max-width: 399px){
    .comm-header-memo {
        font-size: 3.5vw;
    }
    .export-button-memo {
        padding: 0 !important;
        width: 150% !important;
        margin-left: 55%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-memo{
        margin-top: -7%;
    }
    .dropdown-btn-memo {
        margin-left: 0 !important;
        width: 15vw;
    }

}

/* IPhone XR */
@media (min-width: 414px) and (max-width: 420px) and (-webkit-device-pixel-ratio : 2)and (orientation : portrait) {
    .comm-header-memo {
        font-size: 3.8vw;
    }
    .export-button-memo {
        padding: 0 !important;
        width: 150% !important;
        margin-left: 55%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-memo{
        margin-top: -6%;
    }
    .dropdown-btn-memo {
        margin-left: 0 !important;
        width: 15vw;
    }
}

/* IPhone 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
    .comm-header-memo {
        font-size: 3.8vw;
    }
    .export-button-memo {
        padding: 0 !important;
        width: 150% !important;
        margin-left: 55%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-memo{
        margin-top: -6%;
    }
    .dropdown-btn-memo {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 400px) and (max-width: 412px){
    .comm-header-memo {
        font-size: 3.8vw;
    }
    .export-button-memo {
        padding: 0 !important;
        width: 150% !important;
        margin-left: 55%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-memo{
        margin-top: -6%;
    }
    .dropdown-btn-memo {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 540px) and (max-width: 767px) { 
    .comm-header-memo {
        font-size: 4.3vw;
    }
    .export-button-memo {
        padding: 0 !important;
        width: 150% !important;
        margin-left: 55%;
        margin-top: 10%;
        font-size: 12px;
        text-align: center;
    }
    .search-memo{
        margin-top: -4%;
    }
    .dropdown-btn-memo {
        margin-left: 0 !important;
        width: 15vw;
    }

}

@media (min-width: 768px) and (max-width: 819px) { 
    .comm-header-memo {
        font-size: 5vw;
    }
    .export-button-memo {
        margin-left: 37%;
    }
    .dropdown-btn-memo {
        margin-left: 55% !important;
        width: 10vw;
    }

}

@media (min-width: 820px) and (max-width: 911px){
    .comm-header-memo{
        font-size: 5vw;
    }
    .export-button-memo {
        margin-left: 35%;
    }
    .dropdown-btn-memo {
        margin-left: 55% !important;
        width: 10vw;
    }

}

@media (min-width: 912px) and (max-width: 1023px) { 
    .comm-header-memo{
        font-size: 5vw;
    }
    .export-button-memo {
        margin-left: 34%;
    }
    .dropdown-btn-memo {
        margin-left: 55% !important;
        width: 10vw;
    }

}

@media (min-width:1024px) and (max-width: 1279px){
    .export-button-memo {
        margin-left: 34%;
    }
    .dropdown-btn-memo {
        margin-left: 55% !important;
        width: 10vw;
    }

}

@media (min-width:1280px) and (max-width: 1290px){
    .export-button-memo {
        margin-left: 34%;
    }

}