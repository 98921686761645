.comm-header-dashboard {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9vw;
    line-height: 46px;
    letter-spacing: 0.02em;
    color: #000000;
    margin-left: 1em;
    margin-top: .8em;
} 

.comm-subheader-dashboard {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8vw;
    letter-spacing: 0.02em;
    color: #000000;
    margin: .5em 1em .5em 1em;
}

.comm-controls-dashboard {
    display: flex;
    justify-content: flex-start;
    /* background: #9db9ff; */
    background: #80C8E3;
    width: 100%;
    height: 3em;
    margin-top: 1.5em;
    float: top
}

.comm-btn-dashboard {
    background: #E8E8E8;
    border: 2px solid #008ec2;
    box-sizing: border-box;
    border-radius: 5px;
    height: 55%;
    width: fit-content;
    padding-left: 1.5%;
    padding-right: 1.5%;
    margin-right: 2%;
    margin-top: 1%;
    margin-left: 2%;
    
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.03vw;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #008ec2;
}

.comm-btn-dashboard:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all .2s;
}

.comm-btn-dashboard:hover {
    background: #60C7EC;
    color: white;
    cursor: pointer;
}

.leave th:nth-child(6) {
    width: 30px;
}

.payroll th:nth-child(1),
.payroll td:nth-child(1) {
    width: 45px;
}

.gender {
    text-transform: capitalize;
}

.employee-details-dashboard {
    width: 80vw;
    margin-left: 2vw;
}

.wrapper .ebr-table-wrapper {
    margin: 1em 0 0 0;
    width: fit-content !important;
    overflow-x: hidden;
}

.wrapper tbody {
    height: 26vh;
}

.employee-details-dashboard tr {
    border-style: hidden;
}

.employee-details-dashboard th, .employee-details-dashboard td {
    font-size: 16px;
    text-align: left;
    line-height: 0.8;
}

.employee-details-dashboard td {
    font-family: "Source Sans Pro", sans-serif;
    text-align: left;
}

.employee-details-dashboard th {
    font-family: "Source Sans Pro", sans-serif;
    /* margin-left: 5% !important; */
}

.employee-details-dashboard table {
    margin-left: 0;
    padding: 0;
}

.employee-details-wrapper {
    width: 100%;
    display: table;
    table-layout: fixed;
}

/* tabs */
.tabs-wrapper tbody {
    max-height: 16vh;
}

.tabs-dashboard {
    margin-left: 4vw;
}

.tabs-dashboard .nav-link {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: black;
    text-transform: uppercase;
}

.tabs-dashboard .nav-link.active, .tabs-dashboard .show>.nav-link {
    border-bottom: 2px solid !important;
    border-top: none;
    border-left: none;
    border-right: none;
    color: black !important;
    background-color: transparent !important;
    border-color: none none black none;
}

.tabs-dashboard .nav-link:focus, .tabs-dashboard .nav-link:hover {
    color: black;
}

.dashboard-section-container {
    background-color: white;
    border-radius: 1em;
    padding: 2%;
}

.dashboard-subheader {
    font-family: "Ubuntu", sans-serif;
    font-size: 1vw;
    color: #000000;
}

.welcome-msg {
    /* font-family: "Ubuntu", sans-serif; */
    font-size: 100%;
    font-weight: bold;
    color: var(--primary-color);
}

.birthday-height {
    height: 47vh;
    max-height: 47vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.profile-height {
    height: 19vh;
        max-height: 19vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.memo-height {
    height: 23vh;
        max-height: 23vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.req-height {
    height: 20vh;
    max-height: 20vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.adjusted-font {
    font-size: 150% !important;
    color: var(--text-color) !important;
}
.orange {
    color: orange;
}

.primary-color {
    color: var(--primary-color) !important;
}

.text-color {
    color: var(--text-color) !important;
}
.activity-date {
    font-size: 70%;
    font-weight: lighter;
    color: gray;
    font-family: "Ubuntu", sans-serif;
}

.wrap-text {
    overflow-wrap: break-word !important;
}
.box{
    background-color: #d3f3fa;
}
.grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-gap: 2vh; */
}
@media (min-width: 280px) and (max-width: 359px) {
    .comm-header-dashboard {
        font-size: 6vw;
    }

    .comm-controls-dashboard {
        width: 315% !important;
    }

    .comm-subheader-dashboard {
        font-size: 5vw;        
    }
    
    .comm-btn-dashboard {
        height: 55%;
        margin-right: 1%;
        margin-top: 2%;
        margin-left: 1%;
        
        font-size: 2vw;
        padding-left: 3%;
    }
    
    .employee-details-dashboard {
        width: 200vw !important;
    }
    
    .employee-details-wrapper-dashboard {
        width: 120% !important;
    }

    .nav {
        flex-wrap: inherit !important;
    }

}


@media (min-width: 360px) and (max-width: 374px) {
    .comm-header-dashboard {
        font-size: 6vw;
    }

    .comm-controls-dashboard {
        width: 311% !important;
    }

    .comm-subheader-dashboard {
        font-size: 5vw;        
    }
    
    .comm-btn-dashboard {
        height: 55%;
        margin-right: 1%;
        margin-top: 1%;
        margin-left: 1%;
        
        font-size: 2vw;
        padding-left: 3%;
    }
    
    .employee-details-dashboard {
        width: 200vw !important;
    }
    
    .employee-details-wrapper-dashboard {
        width: 120% !important;
    }

    .nav {
        flex-wrap: inherit !important;
    }
    
}

@media (min-width: 375px) and (max-width: 389px) {
    .comm-header-dashboard {
        font-size: 6vw;
    }

    .comm-controls-dashboard {
        width: 311% !important;
    }

    .comm-subheader-dashboard {
        font-size: 5vw;        
    }
    
    .comm-btn-dashboard {
        height: 55%;
        margin-right: 1%;
        margin-top: 1%;
        margin-left: 1%;
        
        font-size: 2vw;
        padding-left: 3%;
    }
    
    .employee-details-dashboard {
        width: 200vw !important;
    }
    
    .employee-details-wrapper-dashboard {
        width: 120% !important;
    }

    .nav {
        flex-wrap: inherit !important;
    }
    

}

/* IPhone 12 Pro */
@media (min-width: 390px) and (max-width: 392px) and (-webkit-device-pixel-ratio : 3)and (orientation : portrait){
    .comm-header-dashboard {
        font-size: 6vw;
    }

    .comm-controls-dashboard {
        width: 280% !important;
    }

    .comm-subheader-dashboard {
        font-size: 5vw;        
    }
    
    .comm-btn-dashboard {
        height: 55%;
        margin-right: 1%;
        margin-top: 1%;
        margin-left: 1%;
        
        font-size: 2vw;
        padding-left: 3%;
    }
    
    .employee-details-dashboard {
        width: 180vw !important;
    }
    
    .employee-details-wrapper-dashboard {
        width: 120% !important;
    }

    .nav {
        flex-wrap: inherit !important;
    }
    

}

@media (min-width: 393px) and (max-width: 399px){
    .comm-header-dashboard {
        font-size: 6vw;
    }

    .comm-controls-dashboard {
        width: 280% !important;
    }

    .comm-subheader-dashboard {
        font-size: 5vw;        
    }
    
    .comm-btn-dashboard {
        height: 55%;
        margin-right: 1%;
        margin-top: 1%;
        margin-left: 1%;
        
        font-size: 2vw;
        padding-left: 3%;
    }
    
    .employee-details-dashboard {
        width: 180vw !important;
    }
    
    .employee-details-wrapper-dashboard {
        width: 120% !important;
    }

    .nav {
        flex-wrap: inherit !important;
    }
    
}

/* IPhone XR */
@media (min-width: 414px) and (max-width: 420px) and (-webkit-device-pixel-ratio : 2)and (orientation : portrait) {
    .comm-header-dashboard {
        font-size: 6vw;
    }

    .comm-controls-dashboard {
        width: 280% !important;
    }

    .comm-subheader-dashboard {
        font-size: 5vw;        
    }
    
    .comm-btn-dashboard {
        height: 55%;
        margin-right: 1%;
        margin-top: 1%;
        margin-left: 1%;
        
        font-size: 2vw;
        padding-left: 3%;
    }
    
    .employee-details-dashboard {
        width: 180vw !important;
    }
    
    .employee-details-wrapper-dashboard {
        width: 120% !important;
    }

    .nav {
        flex-wrap: inherit !important;
    }  
}

/* IPhone 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
    .comm-header-dashboard {
        font-size: 6vw;
    }

    .comm-controls-dashboard {
        width: 280% !important;
    }

    .comm-subheader-dashboard {
        font-size: 5vw;        
    }
    
    .comm-btn-dashboard {
        height: 55%;
        margin-right: 1%;
        margin-top: 1%;
        margin-left: 1%;
        
        font-size: 2vw;
        padding-left: 3%;
    }
    
    .employee-details-dashboard {
        width: 180vw !important;
    }
    
    .employee-details-wrapper-dashboard {
        width: 120% !important;
    }

    .nav {
        flex-wrap: inherit !important;
    }

}


@media (min-width: 400px) and (max-width: 412px){
    .comm-header-dashboard {
        font-size: 6vw;
    }

    .comm-controls-dashboard {
        width: 280% !important;
    }

    .comm-subheader-dashboard {
        font-size: 5vw;        
    }
    
    .comm-btn-dashboard {
        height: 55%;
        margin-right: 1%;
        margin-top: 1%;
        margin-left: 1%;
        
        font-size: 2vw;
        padding-left: 3%;
    }
    
    .employee-details-dashboard {
        width: 180vw !important;
    }
    
    .employee-details-wrapper-dashboard {
        width: 120% !important;
    }

    .nav {
        flex-wrap: inherit !important;
    }

}


@media (min-width: 540px) and (max-width: 767px) { 
    .comm-header-dashboard {
        font-size: 4vw;
    }

    .comm-controls-dashboard {
        width: 240% !important;
    }

    .comm-subheader-dashboard {
        font-size: 3vw;        
    }
    
    .comm-btn-dashboard {
        height: 55%;
        margin-right: 1%;
        margin-top: 1%;
        margin-left: 1%;
        
        font-size: 2vw;
        padding-left: 3%;
    }
    
    .employee-details-dashboard {
        width: 150vw !important;
    }
    
    .employee-details-wrapper-dashboard {
        width: 120% !important;
    }

    .nav {
        flex-wrap: inherit !important;
    }

  }

  @media (min-width: 768px) and (max-width: 819px) { 
    .comm-controls-dashboard {
        width: 130% !important;
    }
    .employee-details-dashboard {
        width: 100vw !important;
    }
  }

  @media (min-width: 820px) and (max-width: 911px){
    .comm-controls-dashboard {
        width: 120% !important;
    }
    .employee-details-dashboard {
        width: 90vw !important;
    }
  }

@media (min-width: 912px) and (max-width: 1023px) { 
    .comm-controls-dashboard {
        width: 105% !important;
    }
  }

