.wfa-wrapper th:nth-child(1),
.wfa-wrapper td:nth-child(1) {
    text-align: left;
    padding: 0.5%;
    padding-left: 0 !important;
    width: 20%;
}

.wfa-wrapper th:nth-child(2),
.wfa-wrapper td:nth-child(2),
.wfa-wrapper th:nth-child(3),
.wfa-wrapper td:nth-child(3),
.wfa-wrapper th:nth-child(4),
.wfa-wrapper td:nth-child(4),
.wfa-wrapper th:nth-child(5),
.wfa-wrapper td:nth-child(5),
.wfa-wrapper th:nth-child(6),
.wfa-wrapper td:nth-child(6) {
    text-align: left;
    padding: 0.5%;
    padding-left: 0 !important;
    /* width: 10%; */
}