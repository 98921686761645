.taxwithheld-wrapper tbody {
    max-height: 52vh;
}

.taxwithheld-wrapper th:nth-child(1),
.taxwithheld-wrapper td:nth-child(1),
.taxwithheld-wrapper th:nth-child(2),
.taxwithheld-wrapper td:nth-child(2),
.taxwithheld-wrapper td:nth-child(3) {
    padding: 0.5%;
    padding-left: 0 !important;
    text-align: left;
}

.taxwithheld-wrapper th:nth-child(4),
.taxwithheld-wrapper td:nth-child(4),
.taxwithheld-wrapper th:nth-child(5),
.taxwithheld-wrapper td:nth-child(5),
.taxwithheld-wrapper th:nth-child(6),
.taxwithheld-wrapper td:nth-child(6),
.taxwithheld-wrapper th:nth-child(7),
.taxwithheld-wrapper td:nth-child(7) {
    padding: 0.5%;
    padding-right: 0 !important;
    text-align: right !important;
}


a {
    text-decoration: none;
    color: inherit;
}


.yes-border {
    color: var(--primary-color);
    font-size: 1vw;
    padding-right: 25% !important;
    border-color: var(--primary-color) !important;
}

.no-border {
    border-color: white !important;
}

.taxwithheld-form-control {
    border: 1px solid var(--primary-color) !important;
    border-radius: 4px !important;
    width: 10vw !important
}

.filter-btn-wrapper {
    position: relative;
    height: 100%;
}

.filter-btn-wrapper button {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    width: 80% !important;
}

.taxwithheld-table thead {
    border-top: 2px solid var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
    text-transform: uppercase;
    /* overflow-x: scroll !important; */
}

.taxwithheld-table thead th {
    width: 10vw !important;
}

.taxwithheld-table thead tr,
.taxwithheld-table tbody tr {
    display: block;
    vertical-align: middle;
}

.taxwithheld-table {
    border-bottom: 1px solid var(--fourth-color);
    overflow-x: scroll !important;
    table-layout: auto;
}

.nochange:hover {
    color: inherit !important;
}


.blue {
    background-color: #054c87 !important;
}

@media screen {
    .printOnly {
        display: none;
    }
}

@media print {
    .noprint {
            display: none;
        }
    @page {
        width: 100vw !important;
        size: auto;
        margin: 16mm 16mm 16mm 16mm;
    }
}


.print-btn-act {
    background-color: var(--primary-color);
    border: none;
    color: white;
    border-radius: 250em;
    width: 5vw !important;
}

.no-margin-top {
    margin-top: -2vw !important;
}

.no-margin-bottom {
    margin-bottom: -1vw !important;
}

.view-taxwithheld {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 90%;
    font-weight: bold;
    stroke: none;
    stroke-width: 0;
    margin-left: 10%;
}

.content-taxwithheld {
    text-align: right;
}

.title-taxwithheld {
    text-align: left;
    padding-left: 0.5%;
}

.leftCol {
    flex: 0 0 30%;
    background-color: none;
    display: right;
    align-items: left;
    padding: 0%;
    padding-left: 1%;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}

.midCol {
    flex: 0 0 5%;
    background-color: none;
    display: right;
    align-items: left;
    padding: 0%;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}

.rightCol {
    flex: 0 0 25%;
    background-color: none;
    display: flex;
    padding: 0%;
    align-items: top;
    justify-content: left;
    list-style-type: none;
    margin-bottom: 10px;
}

.bir-form {
    max-width: 1000px;
    height: auto;
}

body {
    background-color: #FFF;
}

.s1 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 7pt;
    }
    
    .s2 {
        color: black;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        font-size: 7pt;
}

h1 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 23.5pt;
}

h2 {
    color: black;
    font-family: Arial, sans-serif;
        font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 14.5pt;
}

.s3 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: italic;
    font-weight: normal;
    text-decoration: none;
    font-size: 6pt;
}

.h4 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7.5pt;
}

h3 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10.5pt;
}

.s5 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    font-size: 6pt;
}

.s6 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 6pt;
}

.s7 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 6.5pt;
}

#l1 {
    padding-left:
        0pt;
            counter-reset:
                c1 1;
}
#l1>li>*:first-child:before {
    counter-increment: c1;
    content: counter(c1, upper-latin)". ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7pt;
}
#l1>li:first-child>*:first-child:before {
    counter-increment: c1 0;
}

#l2 {
    padding-left: 0pt;
    counter-reset: d1 29;
}
#l2>li>*:first-child:before {
    counter-increment: d1;
    content: counter(d1, decimal)" ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7pt;
}
#l2>li:first-child>*:first-child:before {
    counter-increment: d1 0;
}

#l3 {
    padding-left: 0pt;
    counter-reset: e1 12;
}
#l3>li>*:first-child:before {
    counter-increment: e1;
    content: counter(e1, decimal)" ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7pt;
}
#l3>li:first-child>*:first-child:before {
    counter-increment: e1 0;
}

#l4 {
    padding-left: 0pt;
    counter-reset: e1 13;
}
#l4>li>*:first-child:before {
    counter-increment: e1;
    content: counter(e1, decimal)" ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7pt;
}
#l4>li:first-child>*:first-child:before {
    counter-increment: e1 0;
}

#l5 {
    padding-left: 0pt;
    counter-reset: d1 39;
}
#l5>li>*:first-child:before {
    counter-increment: d1;
    content: counter(d1, decimal)" ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7pt;
}
#l5>li:first-child>*:first-child:before {
    counter-increment: d1 0;
}

#l6 {
    padding-left: 0pt;
    counter-reset: d1 41;
}
#l6>li>*:first-child:before {
    counter-increment: d1;
    content: counter(d1, decimal)" ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7pt;
}
#l6>li:first-child>*:first-child:before {
    counter-increment: d1 0;
}

#l7 {
    padding-left: 0pt;
    counter-reset: d1 42;
}
#l7>li>*:first-child:before {
    counter-increment: d1;
    content: counter(d1, decimal)" ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7pt;
}
#l7>li:first-child>*:first-child:before {
    counter-increment: d1 0;
}

#l8 {
    padding-left: 0pt;
    counter-reset: d1 45;
}
#l8>li>*:first-child:before {
    counter-increment: d1;
    content: counter(d1, decimal)" ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7pt;
}
#l8>li:first-child>*:first-child:before {
    counter-increment: d1 0;
}

#l9 {
    padding-left: 0pt;
    counter-reset: d1 46;
}
#l9>li>*:first-child:before {
    counter-increment: d1;
    content: counter(d1, decimal)" ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 7pt;
    }
    
    #l9>li:first-child>*:first-child:before {
        counter-increment: d1 0;
}