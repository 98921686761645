.form-wrapper tbody {
    max-height: 52vh;
}

.form-wrapper td:nth-child(1),
.form-wrapper th:nth-child(1),
.form-wrapper td:nth-child(3),
.form-wrapper th:nth-child(3) {
    width: 25%;
    text-align: left;
    padding: 0.5%;
    padding-left: 0 !important;
}
.form-wrapper td:nth-child(2),
.form-wrapper th:nth-child(2),
.form-wrapper td:nth-child(4),
.form-wrapper th:nth-child(4) {
    text-align: left;
    padding: 0.5%;
        padding-left: 0 !important;
}

.form-text{
    font-weight: bold;
    color: #2EB1A1;
    font-size: 1.2vw;
}

.result-text{
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.5vw;
    /* font-weight: bold; */
    color: #3d9889;;
    font-size: 1.2vw;    
}

.trash-icon {
    background-color: white;
    border: none;
    border-color: none;
    color: rgba(223, 86, 86, 0.951);
}

.add-icon {
    background-color: white;
    border: none;
    border-color: none;
    color: green;
}

.create-form-container {
    background-color: white;
    padding: 1%;
    border-radius: 2em;
    box-shadow: 8px 8px 8px -6px rgba(0, 0, 0, 0.3);
}
.question-bg {
    background-color: var(--secondary-color);
    padding: 1.5% 1%;
    border-radius: 0.5em;
    overflow-x: hidden;
}
.view-question {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 90%;
    font-weight: bold;
    stroke: none;
    stroke-width: 0;
    margin-left: 10%;
}

.view-answer {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 90%;
    stroke: none;
    stroke-width: 0;
    margin-left: 10%;
    margin-right: 10%;
}