.thirteenth-month-table-wrapper td:nth-child(1),
.thirteenth-month-table-wrapper th:nth-child(1) {
    text-align: left;
    padding: 0.5%;
    padding-left: 0 !important;
    width: 17%;
}

.thirteenth-month-table-wrapper td:nth-child(2),
.thirteenth-month-table-wrapper th:nth-child(2),
.thirteenth-month-table-wrapper td:nth-child(3),
.thirteenth-month-table-wrapper th:nth-child(3),
.thirteenth-month-table-wrapper td:nth-child(4),
.thirteenth-month-table-wrapper th:nth-child(4),
.thirteenth-month-table-wrapper td:nth-child(5),
.thirteenth-month-table-wrapper th:nth-child(5),
.thirteenth-month-table-wrapper td:nth-child(6),
.thirteenth-month-table-wrapper th:nth-child(6),
.thirteenth-month-table-wrapper td:nth-child(7),
.thirteenth-month-table-wrapper th:nth-child(7),
.thirteenth-month-table-wrapper td:nth-child(8),
.thirteenth-month-table-wrapper th:nth-child(8),
.thirteenth-month-table-wrapper td:nth-child(9),
.thirteenth-month-table-wrapper th:nth-child(9),
.thirteenth-month-table-wrapper td:nth-child(10),
.thirteenth-month-table-wrapper th:nth-child(10),
.thirteenth-month-table-wrapper td:nth-child(11),
.thirteenth-month-table-wrapper th:nth-child(11),
.thirteenth-month-table-wrapper td:nth-child(12),
.thirteenth-month-table-wrapper th:nth-child(12),
.thirteenth-month-table-wrapper td:nth-child(13),
.thirteenth-month-table-wrapper th:nth-child(13) {
    padding: 0.5%;
    padding-left: 0 !important;
    text-align: right;
    width: 6.66%;
}

.thirteenth-month-table-wrapper th,
.thirteenth-month-table-wrapper td {
    font-size: 100%;
    display: inline-block;
}

.thirteenth-month-table-wrapper tr {

    display: flex;
    overflow-y: scroll;
}

.header p {
    margin-bottom: 0 !important;
}

/* contracts */
.contracts .nav-link {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    color: black;
    text-transform: uppercase;
}

.contracts .nav-link.active,
.contracts .show>.nav-link {
    border-bottom: 2px solid !important;
    border-top: none;
    border-left: none;
    border-right: none;
    color: black !important;
    background-color: transparent !important;
    border-color: none none black none;
}

.contracts .nav-link:focus,
.contracts .nav-link:hover {
    color: black;
}

.search-bar {
    border: none !important;
    border-radius: 999em 999em 999em 999em !important;
    height: 125%;
}

.search-part {
    border: none !important;
    border-radius: 0 999em 999em 0 !important;
    height: 125%;
    box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.3);
}

.icon-part {
    border: none !important;
    border-radius: 999em 0 0 999em !important;
    height: 125%;
    background-color: white;
    color: var(--primary-color);
    box-shadow: 8px 8px 8px -6px rgba(0, 0, 0, 0.3);
}

.filter-item {
    width: 10px !important;
    /* background-color: yellow; */
}

.comm-header-thirteenthMonth {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.9vw;
    line-height: 46px;
    letter-spacing: 0.02em;
    /* color: #000000; */
    color: var(--fourth-color);
    /* margin-left: 1em; */
    margin-top: .0em;
}

.export-button-thirteenthMonth {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 8%;
    border-radius: 500em;
    width: 150% !important;

    /* border-radius: 999em 999em 999em 999em */
}

.add-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 500em;
    width: 100%
}

.all {
    display: flex;
    justify-content: center;
    /* padding: ; */
}

.active-all {
    background: var(--primary-color) !important;
    color: white !important;
    border-radius: 10em;
}

.margin-less {
    margin-right: -1% !important;
    padding: 0.5%;
}

.dropdown-btn-thirteenthMonth {
    background-color: var(--primary-color);
    border: none;
    border-radius: 10px;
    color: white;
    width: 7vw;
    padding: 1%;
    display: flex;
    justify-content: end !important;
    margin-left: 43% !important;
}

.col-sm-10 {
    width: 95%;
}

.col-button {
    margin: 0;
    padding: 0;
}

@media (min-width: 280px) and (max-width: 359px) {
    .comm-header-thirteenthMonth {
        font-size: 9vw;
    }

    .export-button-thirteenthMonth {
        margin-left: -10%;
        width: 150% !important;
        font-size: 15px;
        margin-top: 2%;
    }

    .export-button {
        width: 150% !important;
        margin-left: -10%;
        padding: 1;
    }

    .search-thirteenthMonth {
        margin-left: 1%;
        margin-bottom: 1%;
    }

    .margin-less {
        margin-right: -1% !important;
        width: 100%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 0 !important;
        width: 25vw;
    }

    .add-button {
        width: 500%;
        margin-left: 10% !important;
    }
}

@media (min-width: 360px) and (max-width: 374px) {
    .comm-header-thirteenthMonth {
        font-size: 9vw;
    }

    .export-button-thirteenthMonth {
        margin-left: 3%;
        width: 180% !important;
        font-size: 15px;
    }

    .export-button {
        width: 180% !important;
        margin-left: 1%;
        padding: 1;
    }

    .search-thirteenthMonth {
        margin-left: 1%;
        margin-bottom: 1%;
    }

    .margin-less {
        margin-right: -1% !important;
        width: 100%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 0 !important;
        width: 25vw;
    }

    .add-button {
        width: 300%;
    }
}

@media (min-width: 375px) and (max-width: 389px) {
    .comm-header-thirteenthMonth {
        font-size: 9vw;
    }

    .export-button-thirteenthMonth {
        margin-left: 4%;
        width: 175% !important;
        font-size: 15px;
    }

    .export-button {
        width: 175% !important;
        margin-left: 1%;
        padding: 1;
    }

    .search-thirteenthMonth {
        margin-left: 1%;
        margin-bottom: 1%;
    }

    .margin-less {
        margin-right: -1% !important;
        width: 100%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 0 !important;
        width: 25vw;
    }

    .add-button {
        width: 300%;
    }
}

/* IPhone 12 Pro */
@media (min-width: 390px) and (max-width: 392px) and (-webkit-device-pixel-ratio : 3)and (orientation : portrait) {
    .comm-header-thirteenthMonth {
        font-size: 9vw;
    }

    .export-button-thirteenthMonth {
        margin-left: -2%;
        width: 170% !important;
        font-size: 15px;
    }

    .export-button {
        width: 170% !important;
        margin-left: 1%;
        padding: 1;
    }

    .search-thirteenthMonth {
        margin-left: 1%;
        margin-bottom: 1%;
    }

    .margin-less {
        margin-right: -1% !important;
        width: 100%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 0 !important;
        width: 22vw;
    }

    .add-button {
        width: 200%;
    }
}

@media (min-width: 393px) and (max-width: 399px) {
    .comm-header-thirteenthMonth {
        font-size: 9vw;
    }

    .export-button-thirteenthMonth {
        margin-left: -2%;
        width: 170% !important;
        font-size: 15px;
    }

    .export-button {
        width: 170% !important;
        margin-left: 1%;
        padding: 1;
    }

    .search-thirteenthMonth {
        margin-left: 1%;
        margin-bottom: 1%;
    }

    .margin-less {
        margin-right: -1% !important;
        width: 100%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 0 !important;
        width: 22vw;
    }

    .add-button {
        width: 200%;
    }
}

/* IPhone XR */
@media (min-width: 414px) and (max-width: 420px) and (-webkit-device-pixel-ratio : 2)and (orientation : portrait) {
    .comm-header-thirteenthMonth {
        font-size: 10vw;
    }

    .export-button-thirteenthMonth {
        margin-left: 1%;
        width: 160% !important;
        font-size: 15px;
        margin-top: 5%;
    }

    .export-button {
        width: 160% !important;
        margin-left: 1%;
        padding: 1;
        margin-top: 5%;
    }

    .search-thirteenthMonth {
        margin-left: 1%;
        margin-bottom: 1%;
    }

    .margin-less {
        margin-right: -1% !important;
        width: 100%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 0 !important;
        width: 22vw;
    }

    .add-button {
        width: 200%;
    }
}

/* IPhone 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    .comm-header-thirteenthMonth {
        font-size: 10vw;
    }

    .export-button-thirteenthMonth {
        margin-left: 1%;
        width: 160% !important;
        font-size: 15px;
        margin-top: 5%;
    }

    .export-button {
        width: 160% !important;
        margin-left: 1%;
        padding: 1;
        margin-top: 5%;
    }

    .search-thirteenthMonth {
        margin-left: 1%;
        margin-bottom: 1%;
    }

    .margin-less {
        margin-right: -1% !important;
        width: 100%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 0 !important;
        width: 22vw;
    }

    .add-button {
        width: 200%;
    }
}

@media (min-width: 400px) and (max-width: 412px) {
    .comm-header-thirteenthMonth {
        font-size: 10vw;
    }

    .export-button-thirteenthMonth {
        margin-left: 1%;
        width: 160% !important;
        font-size: 15px;
        margin-top: 5%;
    }

    .export-button {
        width: 160% !important;
        margin-left: 1%;
        padding: 1;
        margin-top: 5%;
    }

    .search-thirteenthMonth {
        margin-left: 1%;
        margin-bottom: 1%;
    }

    .margin-less {
        margin-right: -1% !important;
        width: 100%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 0 !important;
        width: 22vw;
    }

    .add-button {
        width: 200%;
    }
}

@media (min-width: 540px) and (max-width: 767px) {
    .comm-header-thirteenthMonth {
        font-size: 12.2vw;
        margin-top: 2%;
    }

    .export-button-thirteenthMonth {
        margin-left: -1%;
        width: 160% !important;
        font-size: 15px;
        margin-top: 18%;
    }

    .export-button {
        width: 160% !important;
        margin-left: -5%;
        padding: 1;
        margin-top: 18%;
    }

    .search-thirteenthMonth {
        margin-left: 1%;
        margin-bottom: 1%;
    }

    .margin-less {
        margin-right: -1% !important;
        width: 100%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 0 !important;
        width: 22vw;
    }
}

@media (min-width: 768px) and (max-width: 819px) {
    .comm-header-thirteenthMonth {
        font-size: 5vw;
        margin-left: 1%;
    }

    .export-button-thirteenthMonth {
        margin-left: 8%;
        /* width: 200% !important; */
    }


    .margin-less {
        margin-right: -1% !important;
        width: 25%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 40% !important;
        width: 9vw;
    }
}

@media (min-width: 820px) and (max-width: 911px) {
    .comm-header-thirteenthMonth {
        font-size: 5vw;
        margin-left: 1%;
    }

    .export-button-thirteenthMonth {
        margin-left: 3%;
        /* width: 200% !important; */
    }

    .margin-less {
        margin-right: -1% !important;
        width: 25%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 40% !important;
        width: 9vw;
    }
}

@media (min-width: 912px) and (max-width: 1023px) {
    .comm-header-thirteenthMonth {
        font-size: 5vw;
        margin-left: 1%;
    }

    .export-button-thirteenthMonth {
        margin-left: 3%;
        /* width: 200% !important; */
    }

    .margin-less {
        margin-right: -1% !important;
        width: 20%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 40% !important;
        width: 9vw;
    }
}

@media (min-width:1024px) and (max-width: 1279px) {

    .margin-less {
        margin-right: -1% !important;
        width: 20%
    }

    .dropdown-btn-thirteenthMonth {
        margin-left: 40% !important;
        width: 9vw;
    }
}

@media (min-width:1280px) and (max-width: 1290px) {
    .dropdown-btn-thirteenthMonth {
        margin-left: 40% !important;
        width: 7vw;
    }
}